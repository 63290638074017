import React, { FC } from "react"
import { Image } from "@chakra-ui/react"

export const ProductImage: FC<{ src: string; alt?: string; size?: number }> = ({
  src,
  alt = "",
  size = 116,
}) => (
  <Image
    src={src}
    objectFit="contain"
    boxSize={size}
    alt={alt}
    borderRadius="norm"
    bg="white"
    padding={2}
  />
)
