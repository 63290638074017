import React, { FC, ReactNode } from "react"
import { createRoot } from "react-dom/client"
import { App } from "./App"
import { HtmlProgramData } from "./generated/html_program"
import { AmplitudeTracker } from "./services/amplitude"

export const Error: FC<{ children: ReactNode }> = ({ children }) => (
  <div
    style={{
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {children}
  </div>
)

const el = document.getElementById("root")

const currentUrl = new URL(String(document.location))
const userId = currentUrl.searchParams.get("user_id")

new AmplitudeTracker().logInit({ userId: userId as UserId })

console.debug("Test CI")
if (el) {
  if (userId == null) {
    createRoot(el).render(<Error>Unknown user</Error>)
  } else {
    const API_ENDPOINT = "https://api.pora.ai/programs/html_program_data" /* TODO get from config */
    const apiUrl = new URL(API_ENDPOINT)
    apiUrl.searchParams.set("format", "json")
    apiUrl.searchParams.set("user_id", userId)
    fetch(String(apiUrl))
      .then((resp) => resp.json())
      .then((data) => {
        const parsed = HtmlProgramData.fromJSON(data.program_data)
        createRoot(el).render(<App data={parsed} />)
      })
      .catch((err) => {
        console.error(err)
        // eslint-disable-next-line react/no-unescaped-entities
        createRoot(el).render(<Error>Can't fetch data</Error>)
      })
  }
} else {
  console.error("Can't find #root element")
}
