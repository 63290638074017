import React, { FC, ReactNode } from "react"
import { Box, Stack, Image, Link } from "@chakra-ui/react"
import { ChartCard as ChartCardType } from "../generated/html_program"
import { Card, CardProps, CardParagraph, CardHeader } from "./Card"

const ChartDescription: FC<{ children: ReactNode }> = ({ children }) => (
  <Box bg={"white"} borderRadius="norm" paddingX={4} paddingY={4}>
    {children}
  </Box>
)
const ChartImage: FC<{ src: string; alt?: string }> = ({ src, alt = "" }) => (
  <Image src={src} alt={alt} fit="contain" borderRadius="norm" bg="white" />
)
export const ChartCard: FC<CardProps & ChartCardType> = ({
  id,
  title,
  chart_title,
  chart_image_url,
  description,
  source_link,
}) => (
  <Card id={id}>
    <Stack direction="column" spacing={4}>
      <CardHeader>{title}</CardHeader>
      <CardParagraph>{chart_title}</CardParagraph>
      <ChartImage src={chart_image_url} />
      <ChartDescription>
        {description}{" "}
        {source_link ? (
          <Link isExternal href={source_link}>
            (Read more in article)
          </Link>
        ) : (
          ""
        )}
      </ChartDescription>
    </Stack>
  </Card>
)
