import React, { FC, useCallback } from "react"
import { Box, Stack, Text, Button } from "@chakra-ui/react"

import { ProgramsNavigationCard } from "../generated/html_program"

import { RitualTypeIcons, RitualTypeTranslation } from "./Ritual"

export const ReportNavigation: FC<ProgramsNavigationCard> = ({ buttons }) => {
  const onClick = useCallback(
    (id: string) => () => {
      const el = document.getElementById(id)
      if (el) {
        el.scrollIntoView({ behavior: "smooth" })
      }
    },
    []
  )

  return (
    <Box padding={4}>
      <Stack direction="column" spacing={3}>
        <Text color="brand.600" textStyle="small">
          Tap to quickly navigate
        </Text>

        <Stack direction="row" alignItems="flex-start">
          {buttons.map(({ ritual_type, card_id }) => {
            const leftIcon = RitualTypeIcons[ritual_type]
            let props = {}
            if (leftIcon) {
              props = { leftIcon }
            }
            return (
              <Button key={card_id} onClick={onClick(card_id)} variant="navigation" {...props}>
                {RitualTypeTranslation[ritual_type]}
              </Button>
            )
          })}
        </Stack>
      </Stack>
    </Box>
  )
}
