import { extendTheme } from "@chakra-ui/react"
import { ComponentStyleConfig } from "@chakra-ui/theme"

const colors = {
  brand: {
    "100": "#F1F7F8",
    "300": "#C4E8EE",
    "400": "#00C0EB",
    "400.10": "rgba(0, 192, 235, 0.1)",
    "400.20": "rgba(0, 192, 235, 0.2)",
    "550": "#93A9C1",
    "600": "#73879D",
    "800": "#0C2330",
    WhiteAlpha72: "rgba(255, 255, 255, 0.72)",
  },
}

const textStyles = {
  large: { fontSize: "20px", lineHeight: "26px", fontWeight: 600 },

  mediumExpanded: { fontSize: "16px", lineHeight: "24px" },
  medium: { fontSize: "16px", lineHeight: "22px" },
  mediumCondensed: { fontSize: "16px", lineHeight: "20px" },
  mediumNull: { fontSize: "16px", lineHeight: "16px" },

  small: { fontSize: "14px", lineHeight: "20px" },
  smallCondensed: { fontSize: "14px", lineHeight: "16px" },
}
export type TextStyle = keyof typeof textStyles
const fontSizes = {
  xsmall: "12px",
  icon: "20px",
}
const radii = {
  norm: "1.25rem",
}
const fonts = {
  heading: `'Platform LC Web', sans-serif`,
  body: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif`,
}
const Link: ComponentStyleConfig = {
  baseStyle: {
    color: "auto",
    _hover: {
      textDecoration: "none",
    },
    _active: {
      textDecoration: "none",
    },
  },
}
const Button: ComponentStyleConfig = {
  variants: {
    share: {
      bg: "brand.800",
      color: "white",
      borderRadius: "full",
      _active: { bg: "black" },
      _hover: { bg: "black" },
    },
    navigation: {
      bg: "brand.400.10",
      color: "brand.400",
      borderRadius: "full",
      fontSize: "14px",
      lineHeight: "16px",
      height: "auto",
      px: 3,
      py: 2,
      fontWeight: 500,
      _active: { bg: "brand.400.20" },
      _hover: { bg: "brand.400.20" },
    },
  },
}
const Tag: ComponentStyleConfig = {
  variants: {
    outline: { color: "green", outlineStyle: "dotted" },
  },
}

const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: "heading",
    fontWeight: 500,
  },
  sizes: {
    h1: { fontSize: "32px", lineHeight: "36px" },
    h2: { fontSize: "26px", lineHeight: "32px" },
  },
}

const components = {
  Button,
  Heading,
  Link,
  Tag,
}
const styles = {
  global: {
    a: {
      color: "brand.400",
      _hover: {
        textDecoration: "none",
        color: "black",
      },
      _active: {
        textDecoration: "none",
        color: "black",
      },
    },
  },
}
export const theme = extendTheme({
  colors,
  fonts,
  textStyles,
  fontSizes,
  radii,
  styles,
  components,
})
