import React, { FC, ReactElement } from "react"
import {
  Box,
  Stack,
  Icon,
  Link,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Spacer,
  Center,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"

import { Product, ProductBuyOption, RitualCard, RitualType } from "../generated/html_program"

import { ReactComponent as IconCalendarWhite } from "./icons/icon-calendar-white.svg"
import { ReactComponent as IconSun } from "./icons/icon-sun.svg"
import { ReactComponent as IconSunColor } from "./icons/icon-sun-color.svg"
import { ReactComponent as IconMoon } from "./icons/icon-halfmoon.svg"
import { ReactComponent as IconMoonColor } from "./icons/icon-halfmoon-color.svg"
import { ReactComponent as IconCalendar } from "./icons/icon-calendar.svg"
import { ReactComponent as IconArrow } from "./icons/icon-circle-arrow.svg"
import { ReactComponent as IconForAccordion } from "./icons/accordion-arrow.svg"

import { Card, CardProps } from "./Card"
import { ProductImage } from "./ProductImage"

export const RitualTypeTranslation: Record<RitualType, string> = {
  [RitualType.RITUAL_TYPE_NONE]: "None",
  [RitualType.EVENING]: "Evening",
  [RitualType.MORNING]: "Morning",
  [RitualType.WEEKLY]: "Weekly",
  [RitualType.UNRECOGNIZED]: "Unrecognized",
}

export const RitualTypeIcons: Record<RitualType, ReactElement | null> = {
  [RitualType.RITUAL_TYPE_NONE]: null,
  [RitualType.EVENING]: <IconMoon />,
  [RitualType.MORNING]: <IconSun />,
  [RitualType.WEEKLY]: <IconCalendar />,
  [RitualType.UNRECOGNIZED]: null,
}
const RitualTypeHeaderIcons: Record<RitualType, object | undefined> = {
  [RitualType.RITUAL_TYPE_NONE]: undefined,
  [RitualType.EVENING]: { as: IconMoonColor, boxSize: 6 },
  [RitualType.MORNING]: { as: IconSunColor, boxSize: 7 },
  [RitualType.WEEKLY]: { as: IconCalendarWhite, boxSize: 5, viewBox: "0 0 20 20" },
  [RitualType.UNRECOGNIZED]: undefined,
}

const RitualTypeColorIcon: FC<{ ritualType: RitualType; size?: 5 | 8 }> = ({
  ritualType,
  size = 5,
}) => {
  const props = RitualTypeHeaderIcons[ritualType]
  if (props) {
    return (
      <Center>
        <Icon {...props} width={size} height={size} />
      </Center>
    )
  }
  return null
}

const RitualHeader: FC<{ title: string; subtitle?: string; ritualType: RitualType }> = ({
  title,
  subtitle,
  ritualType,
}) => (
  <Card bg="brand.800">
    <Flex justifyContent="space-between">
      <Stack direction="column">
        <Heading as="h2" size="h2" color="white">
          {title}
        </Heading>
        {subtitle && <Text color="brand.WhiteAlpha72">{subtitle}</Text>}
      </Stack>
      <Spacer />
      <RitualTypeColorIcon ritualType={ritualType} size={8} />
    </Flex>
  </Card>
)

const StepBadge: FC<{ text: string; step: number }> = ({ text, step }) => (
  <Flex
    bg="white"
    borderRadius="full"
    paddingY={0.5}
    paddingLeft={2.5}
    paddingRight={0.5}
    sx={{ maxWidth: "75px" }}
  >
    <Box fontSize="xsmall" lineHeight={5} marginRight={1}>
      {text}
    </Box>
    <Spacer />
    <Center
      bg="brand.100"
      color="brand.400"
      fontWeight="bold"
      borderRadius="full"
      width={6}
      textStyle="smallCondensed"
    >
      {step}
    </Center>
  </Flex>
)

const RitualProduct: FC<CardProps & Product & { stepNumber: number; ritualType: RitualType }> = ({
  title,
  subtitle,
  image_link,
  description: { title: descTitle, text: descText } = {},
  buy_options,
  stepNumber,
  ritualType,
}) => {
  return (
    <Card paddingTop={4}>
      <Stack direction="row" spacing={4}>
        {image_link && <ProductImage src={image_link} alt={title} />}
        <Stack direction="column" flexGrow={1}>
          <Flex justifyContent="space-between">
            <StepBadge text="Step" step={stepNumber} />
            <Spacer />
            <RitualTypeColorIcon ritualType={ritualType} />
          </Flex>
          <Text as="div" textStyle="large" textTransform="capitalize">
            {title}
          </Text>
          <Text as="div" textStyle="small" color="brand.600">
            {subtitle}
          </Text>
        </Stack>
      </Stack>
      <Accordion allowToggle allowMultiple paddingY={4}>
        <RitualProductDescription title={descTitle} text={descText} />
      </Accordion>
      <Text marginBottom="6px" as="h6" textStyle="small" color="brand.600">
        Where to buy:
      </Text>
      <Wrap>
        {buy_options.map((opt, i) => (
          <WrapItem key={i}>
            <RitualProductBuyOptions data={opt} />
          </WrapItem>
        ))}
      </Wrap>
    </Card>
  )
}

const RitualProductDescription: FC<{ title: string | undefined; text: string | undefined }> = ({
  title,
  text,
}) => (
  <AccordionItem borderColor="brand.300">
    {({ isExpanded }) => (
      <>
        <AccordionButton paddingX={0} _hover={{ bg: "none" }} className="AccordionButton">
          <Box flex="1" textAlign="left" fontWeight={500} textStyle="small">
            {title}
          </Box>
          <Icon
            as={IconForAccordion}
            boxSize="20px"
            transform={`rotate(${isExpanded ? 180 : 0}deg)`}
            color="brand.550"
            sx={{
              ".AccordionButton:hover &": { color: "brand.800" },
              transition: "all 150ms",
            }}
          />
        </AccordionButton>
        <AccordionPanel paddingTop={0} paddingBottom={4} paddingX={0}>
          <Text as="p" textStyle="small">
            {text}
          </Text>
        </AccordionPanel>
      </>
    )}
  </AccordionItem>
)

const RitualProductBuyOptions: FC<{ data: ProductBuyOption }> = ({ data: { title, url } }) => (
  <Link
    isExternal
    href={url}
    marginRight={4}
    textStyle="small"
    fontWeight="500"
    whiteSpace="nowrap"
  >
    {title}
    <Icon as={IconArrow} boxSize="20px" marginLeft={1} marginBottom={-1} />
  </Link>
)

export const Ritual: FC<CardProps & RitualCard> = ({ id, title, products, ritual_type }) => {
  return (
    <div id={id}>
      <RitualHeader title={title ?? ""} ritualType={ritual_type} />
      {products.map((c, i) => (
        <RitualProduct {...c} key={i} stepNumber={i + 1} ritualType={ritual_type} />
      ))}
    </div>
  )
}
