import React, { FC, ReactNode, useCallback } from "react"
import {
  Box,
  Stack,
  Image,
  Text,
  Button,
  Icon,
  Flex,
  Spacer,
  StackDivider,
  Tag,
  TagLabel,
  TagLeftIcon,
  Center,
  Heading,
  Container,
} from "@chakra-ui/react"

import { ProgramHeaderCard } from "../generated/html_program"

import PoraLogo from "./icons/pora-ai-logo.svg"
import { ReactComponent as IconSended } from "./icons/icon-sended.svg"
import { ReactComponent as IconShare } from "./icons/icon-share.svg"
import { Card } from "./Card"

const ReportFeaturesList: FC<{ features: ProgramHeaderCard["program_features"] }> = ({
  features,
}) => (
  <Stack direction="column" divider={<StackDivider borderColor="brand.300" />} spacing={4}>
    {features.map(({ key, value }) => (
      <ReportFeature key={key} title={key} value={value} />
    ))}
  </Stack>
)
const ReportFeature: FC<{ title: string; value: ReactNode }> = ({ title, value }) => (
  <Flex textStyle="mediumNull">
    <Box color="brand.600">{title}</Box>
    <Spacer />
    <Box>{value}</Box>
  </Flex>
)
const ShareButton: FC = () => {
  const onClick = useCallback(async () => {
    try {
      await navigator.share({
        title: "Pora.ai skin report",
        text: "",
        url: "https://pora.ai",
      })
    } catch (err) {
      console.error(err)
    }
  }, [])

  if (navigator.canShare && navigator.canShare()) {
    return (
      <Button
        onClick={onClick}
        marginTop={10}
        leftIcon={<Icon as={IconShare} h={"2em"} />}
        variant="share"
        w="100%"
      >
        Share the plan
      </Button>
    )
  }

  return null
}
export const ReportHeader: FC<ProgramHeaderCard> = ({ date, program_number, program_features }) => (
  <Card>
    <Flex marginBottom={6}>
      <Image src={PoraLogo} h="auto" w="auto" />
      <Spacer />
      <Box textStyle="smallCondensed" color="brand.600">
        Created {date}
      </Box>
    </Flex>
    <Heading as="h1" size="h1" marginBottom={4}>
      Personal skin care plan{" "}
      <Text as="span" color="brand.400">
        #{program_number}
      </Text>
    </Heading>
    <Box marginBottom={6}>
      <Tag
        variant="outline"
        borderStyle="dashed"
        boxShadow="none"
        borderColor="brand.300"
        borderWidth="thin"
        px={4}
        py={2}
        borderRadius="full"
      >
        <TagLeftIcon fontSize="28px" as={IconSended} />
        <TagLabel fontWeight="normal">
          <Container textStyle="small" p={0}>
            We have already sent it to your email
          </Container>
        </TagLabel>
      </Tag>
    </Box>
    <ReportFeaturesList features={program_features} />
    <Center>
      <ShareButton />
    </Center>
  </Card>
)
