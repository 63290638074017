import React, { FC } from "react"
import { Box, Icon, LinkBox, LinkOverlay, Flex } from "@chakra-ui/react"

import { FooterCard as FooterType, ThankYouCard as ThankYouType } from "../generated/html_program"

import { ReactComponent as IconMail } from "./icons/icon-mail.svg"
import { ReactComponent as Smile } from "./icons/smile01.svg"
import { Card, CardProps, CardParagraph, CardHeader } from "./Card"

export const FooterCard: FC<CardProps & FooterType> = ({ id }) => (
  <Card id={id} paddingTop={4} paddingBottom={4}>
    <Flex justifyContent="space-between" textStyle="mediumCondensed" marginBottom={1}>
      <Box>© pora</Box>
      <Box>Digital Skincare Inc.</Box>
    </Flex>
    <Flex justifyContent="space-between" textStyle="smallCondensed">
      <Box color="brand.600">2022</Box>
      <Box color="brand.600">Delaware, US</Box>
    </Flex>
  </Card>
)
const EmailButton: FC<{ email: string }> = ({ email }) => (
  <LinkBox
    bg="white"
    borderRadius="full"
    paddingY={2}
    paddingX={4}
    marginTop={6}
    display="inline-block"
    textStyle="smallCondensed"
  >
    <Icon as={IconMail} display="inline-block" w="auto" h="auto" marginBottom={-1} /> Reach us out:{" "}
    <LinkOverlay href={`mailto: ${email}`}>{email}</LinkOverlay>
  </LinkBox>
)
export const ThankYouCard: FC<CardProps & ThankYouType> = ({ id, items, email }) => (
  <Card id={id} paddingTop={4} paddingBottom={4}>
    <Flex marginBottom={6}>
      <CardHeader>Thank you!</CardHeader>
      <Box marginLeft={1}>
        <Smile />
      </Box>
    </Flex>
    {items.map((text, i) => (
      <CardParagraph key={i} color="brand.600" textStyle="mediumCondensed">
        {text}
      </CardParagraph>
    ))}
    <EmailButton email={email} />
  </Card>
)
