import React, { FC } from "react"

import { Card as CardData, HtmlProgramData } from "./generated/html_program"
import {
  ChartCard,
  TextCard,
  UnorderedListCard,
  NumberedListCard,
  IngredientsList,
  ReportHeader,
  ReportNavigation,
  Ritual,
  ThankYouCard,
  FooterCard,
} from "./components"

const CardContentChooser: FC<{ data: CardData }> = ({ data }) => {
  const { card, properties } = data
  const id = properties?.id
  if (card?.$case === "text") {
    return <TextCard id={id} {...card.text} />
  }

  if (card?.$case === "checked_list") {
    return <UnorderedListCard id={id} {...card.checked_list} />
  }

  if (card?.$case === "numbered_list") {
    return <NumberedListCard id={id} {...card.numbered_list} />
  }

  if (card?.$case === "ritual") {
    return <Ritual id={id} {...card.ritual} />
  }

  if (card?.$case === "header") {
    return <ReportHeader {...card.header} />
  }

  if (card?.$case === "navigation") {
    return <ReportNavigation {...card.navigation} />
  }

  if (card?.$case === "chart") {
    return <ChartCard id={id} {...card.chart} />
  }

  if (card?.$case === "ingredients") {
    return <IngredientsList id={id} {...card.ingredients} />
  }

  if (card?.$case === "thank_you") {
    return <ThankYouCard id={id} {...card.thank_you} />
  }

  if (card?.$case === "footer") {
    return <FooterCard id={id} {...card.footer} />
  }

  console.error("Unknown block type")
  console.dir(data)
  return null
}

export const Main: FC<{ data: HtmlProgramData }> = ({ data }) => {
  return (
    <>
      {data.cards.map((c, i) => (
        <CardContentChooser data={c} key={i} />
      ))}
    </>
  )
}
