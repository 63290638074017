import React from "react"
import { Global } from "@emotion/react"

export const Fonts = () => (
  <Global
    styles={`
        @font-face {
          font-style: normal;
          font-weight: 500;
          font-display: fallback;
          font-family: "Platform LC Web";
              src: url("/assets/fonts/Platform-Medium-Web.woff2") format("woff2"),
                url("/assets/fonts/Platform-Medium-Web.woff") format("woff"),
                url("/assets/fonts/Platform-Medium-Web.ttf") format("truetype");
        }
    `}
  />
)
