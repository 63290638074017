import React, { FC } from "react"
import { Stack, Text, StackDivider } from "@chakra-ui/react"

import { IngredientsCard, Ingredient as IngredientType } from "../generated/html_program"

import { Card, CardProps, CardHeader } from "./Card"
import { ProductImage } from "./ProductImage"

const Ingredient: FC<IngredientType> = ({ image_link, title, description }) => (
  <Stack direction="row" spacing={4}>
    {image_link && <ProductImage src={image_link} alt={title} />}
    <Stack direction="column">
      <Text>{title}</Text>
      {description && (
        <Text noOfLines={2} color="brand.600">
          {description}
        </Text>
      )}
    </Stack>
  </Stack>
)

export const IngredientsList: FC<CardProps & IngredientsCard> = ({ id, title, ingredients }) => (
  <Card id={id}>
    <CardHeader>{title}</CardHeader>
    <Stack
      direction="column"
      divider={<StackDivider borderColor="brand.300" />}
      spacing={4}
      marginTop={6}
    >
      {ingredients.map((ingredient, i) => (
        <Ingredient key={i} {...ingredient} />
      ))}
    </Stack>
  </Card>
)
