import UserAgentParser from "ua-parser-js"
export class AmplitudeTracker {
  logInit({ userId }: { userId: UserId | null }) {
    const userAgent = new UserAgentParser(window.navigator.userAgent).getResult()
    const userProperties: Record<string, unknown> = { userAgent }
    const amplitudeUserIdDidntSet = !window.amplitude.getInstance().getUserId()
    if (userId && amplitudeUserIdDidntSet) {
      window.amplitude.getInstance().setUserId(userId)
      userProperties["userId"] = userId
    }

    window.amplitude.getInstance().setUserProperties(userProperties)
    window.amplitude.getInstance().logEvent("report_app_init")
  }
}
