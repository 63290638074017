import React, { FC, ReactNode } from "react"
import { Box, TextProps, Heading, Text } from "@chakra-ui/react"
import { TextStyle } from "../theme"

export const CardHeader: FC<{ children: ReactNode }> = ({ children }) => (
  <Heading as="h2" size="h2">
    {children}
  </Heading>
)

export const CardParagraph: FC<{
  children: ReactNode
  color?: TextProps["color"]
  textStyle?: TextStyle
}> = ({ children, color = "auto", textStyle = "medium" }) => (
  <Text color={color} textStyle={textStyle}>
    {children}
  </Text>
)

type CardId = string
export type CardProps = { id?: CardId | undefined }

export const Card: FC<
  CardProps & { children: ReactNode; bg?: string; paddingTop?: number; paddingBottom?: number }
> = ({ id, children, bg = "brand.100", paddingTop = 6, paddingBottom = 6 }) => (
  <Box
    id={id}
    bg={bg}
    borderRadius="norm"
    marginX={1}
    marginY={2}
    paddingX={4}
    paddingTop={paddingTop}
    paddingBottom={paddingBottom}
  >
    {children}
  </Box>
)
