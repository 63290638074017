/* eslint-disable */
import Long from "long";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "programs_matcher";

export enum RitualType {
  RITUAL_TYPE_NONE = "RITUAL_TYPE_NONE",
  MORNING = "MORNING",
  EVENING = "EVENING",
  WEEKLY = "WEEKLY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function ritualTypeFromJSON(object: any): RitualType {
  switch (object) {
    case 0:
    case "RITUAL_TYPE_NONE":
      return RitualType.RITUAL_TYPE_NONE;
    case 1:
    case "MORNING":
      return RitualType.MORNING;
    case 2:
    case "EVENING":
      return RitualType.EVENING;
    case 3:
    case "WEEKLY":
      return RitualType.WEEKLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RitualType.UNRECOGNIZED;
  }
}

export function ritualTypeToJSON(object: RitualType): string {
  switch (object) {
    case RitualType.RITUAL_TYPE_NONE:
      return "RITUAL_TYPE_NONE";
    case RitualType.MORNING:
      return "MORNING";
    case RitualType.EVENING:
      return "EVENING";
    case RitualType.WEEKLY:
      return "WEEKLY";
    case RitualType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface KeyValueItem {
  key: string;
  value: string;
}

export interface CardProperties {
  id: string;
}

export interface ProgramHeaderCard {
  date: string;
  program_number: string;
  program_features: KeyValueItem[];
}

export interface ProgramsNavigationButton {
  ritual_type: RitualType;
  card_id: string;
}

export interface ProgramsNavigationCard {
  buttons: ProgramsNavigationButton[];
}

export interface NumberedListCard {
  title: string;
  items: string[];
}

export interface CheckedListCard {
  title: string;
  items: string[];
}

export interface ChartCard {
  title: string;
  chart_title: string;
  chart_image_url: string;
  description: string;
  source_link: string;
}

export interface Paragraph {
  text: string;
  source_link: string;
}

export interface TextCard {
  title: string;
  paragraphs: Paragraph[];
}

export interface Ingredient {
  image_link: string;
  title: string;
  description: string;
}

export interface IngredientsCard {
  title: string;
  ingredients: Ingredient[];
}

export interface ProductDescription {
  title: string;
  text: string;
}

export interface Ingredients {
  title: string;
  ingredients: string[];
}

export interface ProductBuyOption {
  title: string;
  url: string;
}

export interface Product {
  title: string;
  subtitle: string;
  step: string;
  image_link: string;
  description: ProductDescription | undefined;
  ingredients: Ingredients | undefined;
  buy_options: ProductBuyOption[];
}

export interface RitualCard {
  title: string;
  ritual_type: RitualType;
  products: Product[];
}

export interface ThankYouCard {
  items: string[];
  email: string;
}

export interface FooterCard {}

export interface Card {
  properties: CardProperties | undefined;
  card?:
    | { $case: "header"; header: ProgramHeaderCard }
    | { $case: "navigation"; navigation: ProgramsNavigationCard }
    | { $case: "numbered_list"; numbered_list: NumberedListCard }
    | { $case: "checked_list"; checked_list: CheckedListCard }
    | { $case: "chart"; chart: ChartCard }
    | { $case: "text"; text: TextCard }
    | { $case: "ingredients"; ingredients: IngredientsCard }
    | { $case: "ritual"; ritual: RitualCard }
    | { $case: "thank_you"; thank_you: ThankYouCard }
    | { $case: "footer"; footer: FooterCard };
}

export interface HtmlProgramData {
  cards: Card[];
}

function createBaseKeyValueItem(): KeyValueItem {
  return { key: "", value: "" };
}

export const KeyValueItem = {
  fromJSON(object: any): KeyValueItem {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? String(object.value) : "",
    };
  },

  toJSON(message: KeyValueItem): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<KeyValueItem>, I>>(
    object: I
  ): KeyValueItem {
    const message = createBaseKeyValueItem();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseCardProperties(): CardProperties {
  return { id: "" };
}

export const CardProperties = {
  fromJSON(object: any): CardProperties {
    return {
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: CardProperties): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CardProperties>, I>>(
    object: I
  ): CardProperties {
    const message = createBaseCardProperties();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseProgramHeaderCard(): ProgramHeaderCard {
  return { date: "", program_number: "", program_features: [] };
}

export const ProgramHeaderCard = {
  fromJSON(object: any): ProgramHeaderCard {
    return {
      date: isSet(object.date) ? String(object.date) : "",
      program_number: isSet(object.program_number)
        ? String(object.program_number)
        : "",
      program_features: Array.isArray(object?.program_features)
        ? object.program_features.map((e: any) => KeyValueItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProgramHeaderCard): unknown {
    const obj: any = {};
    message.date !== undefined && (obj.date = message.date);
    message.program_number !== undefined &&
      (obj.program_number = message.program_number);
    if (message.program_features) {
      obj.program_features = message.program_features.map((e) =>
        e ? KeyValueItem.toJSON(e) : undefined
      );
    } else {
      obj.program_features = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProgramHeaderCard>, I>>(
    object: I
  ): ProgramHeaderCard {
    const message = createBaseProgramHeaderCard();
    message.date = object.date ?? "";
    message.program_number = object.program_number ?? "";
    message.program_features =
      object.program_features?.map((e) => KeyValueItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProgramsNavigationButton(): ProgramsNavigationButton {
  return { ritual_type: RitualType.RITUAL_TYPE_NONE, card_id: "" };
}

export const ProgramsNavigationButton = {
  fromJSON(object: any): ProgramsNavigationButton {
    return {
      ritual_type: isSet(object.ritual_type)
        ? ritualTypeFromJSON(object.ritual_type)
        : RitualType.RITUAL_TYPE_NONE,
      card_id: isSet(object.card_id) ? String(object.card_id) : "",
    };
  },

  toJSON(message: ProgramsNavigationButton): unknown {
    const obj: any = {};
    message.ritual_type !== undefined &&
      (obj.ritual_type = ritualTypeToJSON(message.ritual_type));
    message.card_id !== undefined && (obj.card_id = message.card_id);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProgramsNavigationButton>, I>>(
    object: I
  ): ProgramsNavigationButton {
    const message = createBaseProgramsNavigationButton();
    message.ritual_type = object.ritual_type ?? RitualType.RITUAL_TYPE_NONE;
    message.card_id = object.card_id ?? "";
    return message;
  },
};

function createBaseProgramsNavigationCard(): ProgramsNavigationCard {
  return { buttons: [] };
}

export const ProgramsNavigationCard = {
  fromJSON(object: any): ProgramsNavigationCard {
    return {
      buttons: Array.isArray(object?.buttons)
        ? object.buttons.map((e: any) => ProgramsNavigationButton.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProgramsNavigationCard): unknown {
    const obj: any = {};
    if (message.buttons) {
      obj.buttons = message.buttons.map((e) =>
        e ? ProgramsNavigationButton.toJSON(e) : undefined
      );
    } else {
      obj.buttons = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProgramsNavigationCard>, I>>(
    object: I
  ): ProgramsNavigationCard {
    const message = createBaseProgramsNavigationCard();
    message.buttons =
      object.buttons?.map((e) => ProgramsNavigationButton.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNumberedListCard(): NumberedListCard {
  return { title: "", items: [] };
}

export const NumberedListCard = {
  fromJSON(object: any): NumberedListCard {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: NumberedListCard): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.items) {
      obj.items = message.items.map((e) => e);
    } else {
      obj.items = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NumberedListCard>, I>>(
    object: I
  ): NumberedListCard {
    const message = createBaseNumberedListCard();
    message.title = object.title ?? "";
    message.items = object.items?.map((e) => e) || [];
    return message;
  },
};

function createBaseCheckedListCard(): CheckedListCard {
  return { title: "", items: [] };
}

export const CheckedListCard = {
  fromJSON(object: any): CheckedListCard {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: CheckedListCard): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.items) {
      obj.items = message.items.map((e) => e);
    } else {
      obj.items = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CheckedListCard>, I>>(
    object: I
  ): CheckedListCard {
    const message = createBaseCheckedListCard();
    message.title = object.title ?? "";
    message.items = object.items?.map((e) => e) || [];
    return message;
  },
};

function createBaseChartCard(): ChartCard {
  return {
    title: "",
    chart_title: "",
    chart_image_url: "",
    description: "",
    source_link: "",
  };
}

export const ChartCard = {
  fromJSON(object: any): ChartCard {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      chart_title: isSet(object.chart_title) ? String(object.chart_title) : "",
      chart_image_url: isSet(object.chart_image_url)
        ? String(object.chart_image_url)
        : "",
      description: isSet(object.description) ? String(object.description) : "",
      source_link: isSet(object.source_link) ? String(object.source_link) : "",
    };
  },

  toJSON(message: ChartCard): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.chart_title !== undefined &&
      (obj.chart_title = message.chart_title);
    message.chart_image_url !== undefined &&
      (obj.chart_image_url = message.chart_image_url);
    message.description !== undefined &&
      (obj.description = message.description);
    message.source_link !== undefined &&
      (obj.source_link = message.source_link);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChartCard>, I>>(
    object: I
  ): ChartCard {
    const message = createBaseChartCard();
    message.title = object.title ?? "";
    message.chart_title = object.chart_title ?? "";
    message.chart_image_url = object.chart_image_url ?? "";
    message.description = object.description ?? "";
    message.source_link = object.source_link ?? "";
    return message;
  },
};

function createBaseParagraph(): Paragraph {
  return { text: "", source_link: "" };
}

export const Paragraph = {
  fromJSON(object: any): Paragraph {
    return {
      text: isSet(object.text) ? String(object.text) : "",
      source_link: isSet(object.source_link) ? String(object.source_link) : "",
    };
  },

  toJSON(message: Paragraph): unknown {
    const obj: any = {};
    message.text !== undefined && (obj.text = message.text);
    message.source_link !== undefined &&
      (obj.source_link = message.source_link);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Paragraph>, I>>(
    object: I
  ): Paragraph {
    const message = createBaseParagraph();
    message.text = object.text ?? "";
    message.source_link = object.source_link ?? "";
    return message;
  },
};

function createBaseTextCard(): TextCard {
  return { title: "", paragraphs: [] };
}

export const TextCard = {
  fromJSON(object: any): TextCard {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      paragraphs: Array.isArray(object?.paragraphs)
        ? object.paragraphs.map((e: any) => Paragraph.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TextCard): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.paragraphs) {
      obj.paragraphs = message.paragraphs.map((e) =>
        e ? Paragraph.toJSON(e) : undefined
      );
    } else {
      obj.paragraphs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<TextCard>, I>>(object: I): TextCard {
    const message = createBaseTextCard();
    message.title = object.title ?? "";
    message.paragraphs =
      object.paragraphs?.map((e) => Paragraph.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIngredient(): Ingredient {
  return { image_link: "", title: "", description: "" };
}

export const Ingredient = {
  fromJSON(object: any): Ingredient {
    return {
      image_link: isSet(object.image_link) ? String(object.image_link) : "",
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
    };
  },

  toJSON(message: Ingredient): unknown {
    const obj: any = {};
    message.image_link !== undefined && (obj.image_link = message.image_link);
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined &&
      (obj.description = message.description);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Ingredient>, I>>(
    object: I
  ): Ingredient {
    const message = createBaseIngredient();
    message.image_link = object.image_link ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseIngredientsCard(): IngredientsCard {
  return { title: "", ingredients: [] };
}

export const IngredientsCard = {
  fromJSON(object: any): IngredientsCard {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      ingredients: Array.isArray(object?.ingredients)
        ? object.ingredients.map((e: any) => Ingredient.fromJSON(e))
        : [],
    };
  },

  toJSON(message: IngredientsCard): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.ingredients) {
      obj.ingredients = message.ingredients.map((e) =>
        e ? Ingredient.toJSON(e) : undefined
      );
    } else {
      obj.ingredients = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<IngredientsCard>, I>>(
    object: I
  ): IngredientsCard {
    const message = createBaseIngredientsCard();
    message.title = object.title ?? "";
    message.ingredients =
      object.ingredients?.map((e) => Ingredient.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductDescription(): ProductDescription {
  return { title: "", text: "" };
}

export const ProductDescription = {
  fromJSON(object: any): ProductDescription {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      text: isSet(object.text) ? String(object.text) : "",
    };
  },

  toJSON(message: ProductDescription): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.text !== undefined && (obj.text = message.text);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProductDescription>, I>>(
    object: I
  ): ProductDescription {
    const message = createBaseProductDescription();
    message.title = object.title ?? "";
    message.text = object.text ?? "";
    return message;
  },
};

function createBaseIngredients(): Ingredients {
  return { title: "", ingredients: [] };
}

export const Ingredients = {
  fromJSON(object: any): Ingredients {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      ingredients: Array.isArray(object?.ingredients)
        ? object.ingredients.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: Ingredients): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.ingredients) {
      obj.ingredients = message.ingredients.map((e) => e);
    } else {
      obj.ingredients = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Ingredients>, I>>(
    object: I
  ): Ingredients {
    const message = createBaseIngredients();
    message.title = object.title ?? "";
    message.ingredients = object.ingredients?.map((e) => e) || [];
    return message;
  },
};

function createBaseProductBuyOption(): ProductBuyOption {
  return { title: "", url: "" };
}

export const ProductBuyOption = {
  fromJSON(object: any): ProductBuyOption {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      url: isSet(object.url) ? String(object.url) : "",
    };
  },

  toJSON(message: ProductBuyOption): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProductBuyOption>, I>>(
    object: I
  ): ProductBuyOption {
    const message = createBaseProductBuyOption();
    message.title = object.title ?? "";
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseProduct(): Product {
  return {
    title: "",
    subtitle: "",
    step: "",
    image_link: "",
    description: undefined,
    ingredients: undefined,
    buy_options: [],
  };
}

export const Product = {
  fromJSON(object: any): Product {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      step: isSet(object.step) ? String(object.step) : "",
      image_link: isSet(object.image_link) ? String(object.image_link) : "",
      description: isSet(object.description)
        ? ProductDescription.fromJSON(object.description)
        : undefined,
      ingredients: isSet(object.ingredients)
        ? Ingredients.fromJSON(object.ingredients)
        : undefined,
      buy_options: Array.isArray(object?.buy_options)
        ? object.buy_options.map((e: any) => ProductBuyOption.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Product): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.step !== undefined && (obj.step = message.step);
    message.image_link !== undefined && (obj.image_link = message.image_link);
    message.description !== undefined &&
      (obj.description = message.description
        ? ProductDescription.toJSON(message.description)
        : undefined);
    message.ingredients !== undefined &&
      (obj.ingredients = message.ingredients
        ? Ingredients.toJSON(message.ingredients)
        : undefined);
    if (message.buy_options) {
      obj.buy_options = message.buy_options.map((e) =>
        e ? ProductBuyOption.toJSON(e) : undefined
      );
    } else {
      obj.buy_options = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Product>, I>>(object: I): Product {
    const message = createBaseProduct();
    message.title = object.title ?? "";
    message.subtitle = object.subtitle ?? "";
    message.step = object.step ?? "";
    message.image_link = object.image_link ?? "";
    message.description =
      object.description !== undefined && object.description !== null
        ? ProductDescription.fromPartial(object.description)
        : undefined;
    message.ingredients =
      object.ingredients !== undefined && object.ingredients !== null
        ? Ingredients.fromPartial(object.ingredients)
        : undefined;
    message.buy_options =
      object.buy_options?.map((e) => ProductBuyOption.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRitualCard(): RitualCard {
  return { title: "", ritual_type: RitualType.RITUAL_TYPE_NONE, products: [] };
}

export const RitualCard = {
  fromJSON(object: any): RitualCard {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      ritual_type: isSet(object.ritual_type)
        ? ritualTypeFromJSON(object.ritual_type)
        : RitualType.RITUAL_TYPE_NONE,
      products: Array.isArray(object?.products)
        ? object.products.map((e: any) => Product.fromJSON(e))
        : [],
    };
  },

  toJSON(message: RitualCard): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.ritual_type !== undefined &&
      (obj.ritual_type = ritualTypeToJSON(message.ritual_type));
    if (message.products) {
      obj.products = message.products.map((e) =>
        e ? Product.toJSON(e) : undefined
      );
    } else {
      obj.products = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RitualCard>, I>>(
    object: I
  ): RitualCard {
    const message = createBaseRitualCard();
    message.title = object.title ?? "";
    message.ritual_type = object.ritual_type ?? RitualType.RITUAL_TYPE_NONE;
    message.products =
      object.products?.map((e) => Product.fromPartial(e)) || [];
    return message;
  },
};

function createBaseThankYouCard(): ThankYouCard {
  return { items: [], email: "" };
}

export const ThankYouCard = {
  fromJSON(object: any): ThankYouCard {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => String(e))
        : [],
      email: isSet(object.email) ? String(object.email) : "",
    };
  },

  toJSON(message: ThankYouCard): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e);
    } else {
      obj.items = [];
    }
    message.email !== undefined && (obj.email = message.email);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ThankYouCard>, I>>(
    object: I
  ): ThankYouCard {
    const message = createBaseThankYouCard();
    message.items = object.items?.map((e) => e) || [];
    message.email = object.email ?? "";
    return message;
  },
};

function createBaseFooterCard(): FooterCard {
  return {};
}

export const FooterCard = {
  fromJSON(_: any): FooterCard {
    return {};
  },

  toJSON(_: FooterCard): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<FooterCard>, I>>(_: I): FooterCard {
    const message = createBaseFooterCard();
    return message;
  },
};

function createBaseCard(): Card {
  return { properties: undefined, card: undefined };
}

export const Card = {
  fromJSON(object: any): Card {
    return {
      properties: isSet(object.properties)
        ? CardProperties.fromJSON(object.properties)
        : undefined,
      card: isSet(object.header)
        ? { $case: "header", header: ProgramHeaderCard.fromJSON(object.header) }
        : isSet(object.navigation)
        ? {
            $case: "navigation",
            navigation: ProgramsNavigationCard.fromJSON(object.navigation),
          }
        : isSet(object.numbered_list)
        ? {
            $case: "numbered_list",
            numbered_list: NumberedListCard.fromJSON(object.numbered_list),
          }
        : isSet(object.checked_list)
        ? {
            $case: "checked_list",
            checked_list: CheckedListCard.fromJSON(object.checked_list),
          }
        : isSet(object.chart)
        ? { $case: "chart", chart: ChartCard.fromJSON(object.chart) }
        : isSet(object.text)
        ? { $case: "text", text: TextCard.fromJSON(object.text) }
        : isSet(object.ingredients)
        ? {
            $case: "ingredients",
            ingredients: IngredientsCard.fromJSON(object.ingredients),
          }
        : isSet(object.ritual)
        ? { $case: "ritual", ritual: RitualCard.fromJSON(object.ritual) }
        : isSet(object.thank_you)
        ? {
            $case: "thank_you",
            thank_you: ThankYouCard.fromJSON(object.thank_you),
          }
        : isSet(object.footer)
        ? { $case: "footer", footer: FooterCard.fromJSON(object.footer) }
        : undefined,
    };
  },

  toJSON(message: Card): unknown {
    const obj: any = {};
    message.properties !== undefined &&
      (obj.properties = message.properties
        ? CardProperties.toJSON(message.properties)
        : undefined);
    message.card?.$case === "header" &&
      (obj.header = message.card?.header
        ? ProgramHeaderCard.toJSON(message.card?.header)
        : undefined);
    message.card?.$case === "navigation" &&
      (obj.navigation = message.card?.navigation
        ? ProgramsNavigationCard.toJSON(message.card?.navigation)
        : undefined);
    message.card?.$case === "numbered_list" &&
      (obj.numbered_list = message.card?.numbered_list
        ? NumberedListCard.toJSON(message.card?.numbered_list)
        : undefined);
    message.card?.$case === "checked_list" &&
      (obj.checked_list = message.card?.checked_list
        ? CheckedListCard.toJSON(message.card?.checked_list)
        : undefined);
    message.card?.$case === "chart" &&
      (obj.chart = message.card?.chart
        ? ChartCard.toJSON(message.card?.chart)
        : undefined);
    message.card?.$case === "text" &&
      (obj.text = message.card?.text
        ? TextCard.toJSON(message.card?.text)
        : undefined);
    message.card?.$case === "ingredients" &&
      (obj.ingredients = message.card?.ingredients
        ? IngredientsCard.toJSON(message.card?.ingredients)
        : undefined);
    message.card?.$case === "ritual" &&
      (obj.ritual = message.card?.ritual
        ? RitualCard.toJSON(message.card?.ritual)
        : undefined);
    message.card?.$case === "thank_you" &&
      (obj.thank_you = message.card?.thank_you
        ? ThankYouCard.toJSON(message.card?.thank_you)
        : undefined);
    message.card?.$case === "footer" &&
      (obj.footer = message.card?.footer
        ? FooterCard.toJSON(message.card?.footer)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Card>, I>>(object: I): Card {
    const message = createBaseCard();
    message.properties =
      object.properties !== undefined && object.properties !== null
        ? CardProperties.fromPartial(object.properties)
        : undefined;
    if (
      object.card?.$case === "header" &&
      object.card?.header !== undefined &&
      object.card?.header !== null
    ) {
      message.card = {
        $case: "header",
        header: ProgramHeaderCard.fromPartial(object.card.header),
      };
    }
    if (
      object.card?.$case === "navigation" &&
      object.card?.navigation !== undefined &&
      object.card?.navigation !== null
    ) {
      message.card = {
        $case: "navigation",
        navigation: ProgramsNavigationCard.fromPartial(object.card.navigation),
      };
    }
    if (
      object.card?.$case === "numbered_list" &&
      object.card?.numbered_list !== undefined &&
      object.card?.numbered_list !== null
    ) {
      message.card = {
        $case: "numbered_list",
        numbered_list: NumberedListCard.fromPartial(object.card.numbered_list),
      };
    }
    if (
      object.card?.$case === "checked_list" &&
      object.card?.checked_list !== undefined &&
      object.card?.checked_list !== null
    ) {
      message.card = {
        $case: "checked_list",
        checked_list: CheckedListCard.fromPartial(object.card.checked_list),
      };
    }
    if (
      object.card?.$case === "chart" &&
      object.card?.chart !== undefined &&
      object.card?.chart !== null
    ) {
      message.card = {
        $case: "chart",
        chart: ChartCard.fromPartial(object.card.chart),
      };
    }
    if (
      object.card?.$case === "text" &&
      object.card?.text !== undefined &&
      object.card?.text !== null
    ) {
      message.card = {
        $case: "text",
        text: TextCard.fromPartial(object.card.text),
      };
    }
    if (
      object.card?.$case === "ingredients" &&
      object.card?.ingredients !== undefined &&
      object.card?.ingredients !== null
    ) {
      message.card = {
        $case: "ingredients",
        ingredients: IngredientsCard.fromPartial(object.card.ingredients),
      };
    }
    if (
      object.card?.$case === "ritual" &&
      object.card?.ritual !== undefined &&
      object.card?.ritual !== null
    ) {
      message.card = {
        $case: "ritual",
        ritual: RitualCard.fromPartial(object.card.ritual),
      };
    }
    if (
      object.card?.$case === "thank_you" &&
      object.card?.thank_you !== undefined &&
      object.card?.thank_you !== null
    ) {
      message.card = {
        $case: "thank_you",
        thank_you: ThankYouCard.fromPartial(object.card.thank_you),
      };
    }
    if (
      object.card?.$case === "footer" &&
      object.card?.footer !== undefined &&
      object.card?.footer !== null
    ) {
      message.card = {
        $case: "footer",
        footer: FooterCard.fromPartial(object.card.footer),
      };
    }
    return message;
  },
};

function createBaseHtmlProgramData(): HtmlProgramData {
  return { cards: [] };
}

export const HtmlProgramData = {
  fromJSON(object: any): HtmlProgramData {
    return {
      cards: Array.isArray(object?.cards)
        ? object.cards.map((e: any) => Card.fromJSON(e))
        : [],
    };
  },

  toJSON(message: HtmlProgramData): unknown {
    const obj: any = {};
    if (message.cards) {
      obj.cards = message.cards.map((e) => (e ? Card.toJSON(e) : undefined));
    } else {
      obj.cards = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<HtmlProgramData>, I>>(
    object: I
  ): HtmlProgramData {
    const message = createBaseHtmlProgramData();
    message.cards = object.cards?.map((e) => Card.fromPartial(e)) || [];
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & {
      $case: T["$case"];
    }
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
