import React, { FC } from "react"
import { Box, Stack, OrderedList, UnorderedList, ListItem } from "@chakra-ui/react"
import {
  NumberedListCard as OrderedListType,
  CheckedListCard as UnorderedListType,
  TextCard as TextCardType,
} from "../generated/html_program"
import { Card, CardProps, CardParagraph, CardHeader } from "./Card"

export const TextCard: FC<CardProps & TextCardType> = ({ id, title, paragraphs }) => (
  <Card id={id}>
    <Stack spacing={3}>
      <Box mb={3}>
        <CardHeader>{title}</CardHeader>
      </Box>
      {paragraphs.map((p, i) => (
        <CardParagraph key={i}>{p.text}</CardParagraph>
      ))}
    </Stack>
  </Card>
)
export const UnorderedListCard: FC<CardProps & UnorderedListType> = ({ id, items, title }) => (
  <Card id={id}>
    <Stack spacing={3}>
      <CardHeader>{title}</CardHeader>
      <UnorderedList
        spacing={3}
        listStylePosition="outside"
        listStyleType="none"
        textStyle="mediumExpanded"
      >
        {items.map((p, i) => (
          <ListItem
            key={i}
            position="relative"
            paddingLeft="32px" // 24px + 8px padding
            sx={{ counterIncrement: "my-counter" }}
            _before={{
              "--size": "24px",
              content: "' '",
              position: "absolute",
              lineHeight: "var(--size)",
              width: "var(--size)",
              height: "var(--size)",
              marginLeft: "-32px",
              background: "white",
              backgroundImage: "url(/assets/icons/icon-check.svg)",
              borderRadius: "50%",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            {p}
          </ListItem>
        ))}
      </UnorderedList>
    </Stack>
  </Card>
)
export const NumberedListCard: FC<CardProps & OrderedListType> = ({ id, items, title }) => (
  <Card id={id}>
    <Stack spacing={3}>
      <CardHeader>{title}</CardHeader>
      <OrderedList
        spacing={3}
        listStylePosition="outside"
        listStyleType="none"
        sx={{ counterReset: "my-counter" }}
        textStyle="mediumExpanded"
      >
        {items.map((p, i) => (
          <ListItem
            key={i}
            position="relative"
            paddingLeft="32px" // 24px + 8px padding
            sx={{ counterIncrement: "my-counter" }}
            _before={{
              "--size": "24px",
              content: "counter(my-counter)",
              position: "absolute",
              lineHeight: "var(--size)",
              width: "var(--size)",
              height: "var(--size)",
              marginLeft: "-32px",
              background: "white",
              borderRadius: "50%",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            {p}
          </ListItem>
        ))}
      </OrderedList>
    </Stack>
  </Card>
)
